/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.banner-header:first-child .header-banner {
  margin-top: 0;
}

.header-banner {
  position: relative;
  margin: 2rem 0 1rem;
}

@media print, screen and (max-width: 39.99875em) {
  .header-banner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 1.844rem;
  }
}
.header-banner__image {
  height: 19.063rem;
  overflow: hidden;
  border-radius: 23px;
}

@media print, screen and (max-width: 39.99875em) {
  .header-banner__image {
    height: 12.5rem;
    position: relative;
  }
}
.header-banner__image picture img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.header-banner__title {
  bottom: 1.875rem;
  left: 3.1875rem;
  position: absolute;
}

@media print, screen and (max-width: 39.99875em) {
  .header-banner__title {
    left: 1rem;
  }
}
.header-banner__title p {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 2rem;
  color: #fff;
  line-height: 2rem;
  font-weight: bold;
  border-radius: 23px;
  background-color: var(--primary-color);
  padding: 0.281rem 1.25rem;
  margin-bottom: -1px;
}

@media print, screen and (max-width: 39.99875em) {
  .header-banner__title p {
    font-size: 1.25rem;
    line-height: 1.75rem;
    border-radius: 19px;
    padding: 0.188rem 1rem;
  }
}
.header-banner__title.blue-teal p {
  background-color: #007590;
}

.header-banner__title.purple p {
  background-color: #9d5190;
}

.header-banner__circle {
  width: 12.938rem;
  height: 12.938rem;
  border-radius: 50%;
  position: absolute;
  bottom: 2.75rem;
  right: 2.75rem;
  outline: 5.5px solid #61917F;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 8px;
}

@media print, screen and (max-width: 39.99875em) {
  .header-banner__circle {
    bottom: 0;
    right: 0;
    position: relative;
  }
}
.header-banner__circle:after {
  content: "";
  width: 12.25rem;
  height: 12.25rem;
  border-radius: 50%;
  background: #154734;
  position: absolute;
  left: 5.5px;
  bottom: 5.5px;
}

.header-banner__circle span, .header-banner__circle p {
  z-index: 200;
  margin-bottom: 0;
  line-height: 2.5rem;
}