/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.full-background.green {
  background-color: var(--background-color);
}

.slider-avis-verifies {
  padding: 2rem 0;
}

.slider-avis-verifies__heading {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 2rem;
}

@media print, screen and (max-width: 47.99875em) {
  .slider-avis-verifies__heading {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.slider-avis-verifies__heading h2 {
  margin-bottom: 0;
}

.slider-avis-verifies__grade {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
}

@media print, screen and (max-width: 47.99875em) {
  .slider-avis-verifies__grade {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 0;
  }
}
.slider-avis-verifies__grade--value {
  margin-right: 2rem;
}

@media print, screen and (max-width: 47.99875em) {
  .slider-avis-verifies__grade--value {
    margin-right: 0;
  }
}
.slider-avis-verifies__grade--value .text-headline {
  font-weight: bold;
}

.slider-avis-verifies .avisverifies {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

@media print, screen and (max-width: 47.99875em) {
  .slider-avis-verifies .avisverifies {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-top: 0.5rem;
  }
}
.slider-avis-verifies .avisverifies__star-container {
  background-image: url("/content/dam/cetelem/components/hp_info-card-reinsurance/avisverifies-star-empty.svg");
  background-repeat: repeat-x;
  background-size: 26px;
  content: "";
  height: 26px;
  margin-bottom: 0.2rem;
  position: relative;
  width: 130px;
}

.slider-avis-verifies .avisverifies__star-container--rate {
  background-image: url("/content/dam/cetelem/components/hp_info-card-reinsurance/avisverifies-star-full.svg");
  background-repeat: repeat-x;
  background-size: 26px;
  bottom: 0;
  content: "";
  display: block;
  height: 26px;
  position: absolute;
  width: 130px;
}

.slider-avis-verifies .avisverifies__credentials {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
}

.slider-avis-verifies .avisverifies__credentials__logo {
  margin: auto;
}

.slider-avis-verifies .avisverifies__credentials__reviews {
  color: #292c2e;
  text-decoration: underline;
}

.slider-avis-verifies__slider-container {
  margin: auto;
  padding-bottom: 4rem;
  width: calc(100% - 4rem);
}

.slider-avis-verifies__slider-container .slick-list {
  padding-bottom: 0.5rem;
}

.slider-avis-verifies__slider-container.slick-dotted {
  margin-bottom: 0.5rem;
}

.slider-avis-verifies__slider-container .slick-arrow {
  color: #292c2e;
  height: 2rem;
  width: 2rem;
}

.slider-avis-verifies__slider-container .slick-arrow.slick-disabled {
  opacity: 0.5;
}

.slider-avis-verifies__slider-container .slick-arrow .slider-arrow {
  font-size: 2rem;
}

.slider-avis-verifies__slider-container .slick-prev {
  left: -2rem;
}

.slider-avis-verifies__slider-container .slick-next {
  right: -2rem;
}

.slider-avis-verifies__slider-container .slick-slide {
  padding: 0 1rem;
}

.slider-avis-verifies__slider-container__card {
  background-color: #fff;
  border-radius: 0.5rem;
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  padding: 1rem 1rem 1rem 3rem;
  position: relative;
}

.slider-avis-verifies__slider-container__card .text-quote {
  color: #3a913f;
  font-size: 2rem;
  left: 0.5rem;
  position: absolute;
  top: 1rem;
}

.slider-avis-verifies__slider-container__card p {
  min-height: 6rem;
}

.slider-avis-verifies__slider-container__card .author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: bold;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

@media print, screen and (max-width: 39.99875em) {
  .slider-avis-verifies__slider-container__card .author {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.slider-avis-verifies__slider-container__card .author__name {
  margin-right: 1rem;
}

.slider-avis-verifies__slider-container__card .author__grade {
  margin: 0;
}

@media print, screen and (max-width: 39.99875em) {
  .slider-avis-verifies__slider-container__card .author__grade {
    left: -0.2em;
    margin-top: 0.2em;
  }
}
.slider-avis-verifies__slider-container .slick-dots {
  bottom: 1rem;
}

.slider-avis-verifies__slider-container .slick-dots li button .slick-dot-icon {
  border: 2px solid #292c2e;
  border-radius: 100%;
  display: block;
  height: 1.5rem;
  position: relative;
  width: 1.5rem;
}

.slider-avis-verifies__slider-container .slick-dots .slick-active button .slick-dot-icon::before {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: #292c2e;
  border-radius: 100%;
  content: "";
  display: block;
  height: 0.825rem;
  margin: 0;
  width: 0.825rem;
}