/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.bannerComponent {
  margin: 2rem 0;
  padding: 2rem 2rem;
  border-radius: 1rem;
}

.bannerComponent--bgVert {
  background-color: var(--primary-color);
  color: #fff;
}

.bannerComponent--bgBlue {
  background-color: #007d9b;
  color: #fff;
}

.bannerComponent--bgTeal {
  background-color: #00847f;
  color: #fff;
}

.bannerComponent--bgDarkgreen {
  background-color: #154734;
  color: #fff;
}

.bannerComponent--bgYellow {
  background-color: #feca02;
  color: #fff;
}

.bannerComponent--bgGray {
  background-color: #f2f3f3;
}

.bannerComponent--bgBlanc {
  background-color: transparent;
}

.bannerComponent .button {
  margin-bottom: 0;
}

.bannerComponent .bannerComponent-img {
  text-align: center;
}

.bannerComponent .bannerComponent-title h2 {
  font-size: 1.8rem;
}

.bannerComponent .flexDirectionRowReverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

@media print, screen and (max-width: 39.99875em) {
  .bannerComponent .flexDirectionRowReverse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .bannerComponent {
    padding: 1rem;
  }
  .bannerComponent .bannerComponent-img {
    margin: 2rem 0;
  }
}