/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.thumbnailsProduct .thumbnailsProduct-item .thumbnailsProduct-content .thumbnailsProduct-cta--icon {
  font-family: pf-icon !important;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: never;
  text-transform: none;
}

.thumbnailsProduct .thumbnailsProduct-item .thumbnailsProduct-content .thumbnailsProduct-cta--icon::before {
  content: "\e926";
}

.text-hide {
  font: 0/0 a !important;
  color: transparent !important;
  text-shadow: none !important;
  background-color: transparent !important;
  border: 0 !important;
}

.text-truncate {
  max-width: 100% !important;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-wrap {
  word-wrap: break-word !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-overline {
  text-decoration: overline !important;
}

.text-underline {
  text-decoration: underline !important;
}

.text-line-through {
  text-decoration: line-through !important;
}

.font-wide {
  letter-spacing: 0.25rem;
}

.font-normal {
  font-weight: normal;
}

.font-bold {
  font-weight: bold;
}

.font-italic {
  font-style: italic !important;
}

ul.list-disc {
  list-style-type: disc !important;
}

ul.list-circle {
  list-style-type: circle !important;
}

ul.list-square {
  list-style-type: square !important;
}

ol.list-decimal {
  list-style-type: decimal !important;
}

ol.list-lower-alpha {
  list-style-type: lower-alpha !important;
}

ol.list-lower-latin {
  list-style-type: lower-latin !important;
}

ol.list-lower-roman {
  list-style-type: lower-roman !important;
}

ol.list-upper-alpha {
  list-style-type: upper-alpha !important;
}

ol.list-upper-latin {
  list-style-type: upper-latin !important;
}

ol.list-upper-roman {
  list-style-type: upper-roman !important;
}

.rounded {
  border-radius: 5000px !important;
}

.rounded .switch-paddle {
  border-radius: 5000px !important;
}

.rounded .switch-paddle:after {
  border-radius: 50%;
}

.radius {
  border-radius: 0.1875rem;
}

.bordered {
  border: 0.0625rem solid #cacaca;
}

.shadow {
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
          box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.arrow-down {
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.4375rem;
  content: "";
  border-bottom-width: 0;
  border-color: #2d2d2d transparent transparent;
}

.arrow-up {
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.4375rem;
  content: "";
  border-top-width: 0;
  border-color: transparent transparent #2d2d2d;
}

.arrow-right {
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.4375rem;
  content: "";
  border-right-width: 0;
  border-color: transparent transparent transparent #2d2d2d;
}

.arrow-left {
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.4375rem;
  content: "";
  border-left-width: 0;
  border-color: transparent #2d2d2d transparent transparent;
}

.separator-center {
  text-align: center !important;
}

.separator-center::before, .separator-center::after {
  display: table;
  content: " ";
}

.separator-center::after {
  clear: both;
}

.separator-center::after {
  position: relative !important;
  width: 3rem;
  border-bottom: 0.125rem solid #3a913f;
  margin: 1rem auto 0;
}

.separator-left {
  text-align: left !important;
}

.separator-left::before, .separator-left::after {
  display: table;
  content: " ";
}

.separator-left::after {
  clear: both;
}

.separator-left::after {
  position: relative !important;
  width: 3rem;
  border-bottom: 0.125rem solid #3a913f;
  margin: 1rem auto 0;
  margin-left: 0 !important;
}

.separator-right {
  text-align: right !important;
}

.separator-right::before, .separator-right::after {
  display: table;
  content: " ";
}

.separator-right::after {
  clear: both;
}

.separator-right::after {
  position: relative !important;
  width: 3rem;
  border-bottom: 0.125rem solid #3a913f;
  margin: 1rem auto 0;
  margin-right: 0 !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-scroll {
  overflow: scroll !important;
  -webkit-overflow-scrolling: touch;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
  -webkit-overflow-scrolling: touch;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
  -webkit-overflow-scrolling: touch;
}

.display-inline {
  display: inline !important;
}

.display-inline-block {
  display: inline-block !important;
}

.display-block {
  display: block !important;
}

.display-table {
  display: table !important;
}

.display-table-cell {
  display: table-cell !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-fixed-top {
  position: fixed !important;
  top: 0rem !important;
  right: 0rem !important;
  left: 0rem !important;
  z-index: 975;
}

.position-fixed-bottom {
  position: fixed !important;
  right: 0rem !important;
  bottom: 0rem !important;
  left: 0rem !important;
  z-index: 975;
}

.border-box {
  -webkit-box-sizing: border-box !important;
          box-sizing: border-box !important;
}

.border-none {
  border: 0 !important;
}

.width-25 {
  width: 25% !important;
}

.width-50 {
  width: 50% !important;
}

.width-75 {
  width: 75% !important;
}

.width-100 {
  width: 100% !important;
}

.height-25 {
  height: 25% !important;
}

.height-50 {
  height: 50% !important;
}

.height-75 {
  height: 75% !important;
}

.height-100 {
  height: 100% !important;
}

.max-width-100 {
  max-width: 100% !important;
}

.max-height-100 {
  max-height: 100% !important;
}

.margin-0 {
  margin: 0rem !important;
}

.margin-top-0 {
  margin-top: 0rem !important;
}

.margin-right-0 {
  margin-right: 0rem !important;
}

.margin-bottom-0 {
  margin-bottom: 0rem !important;
}

.margin-left-0 {
  margin-left: 0rem !important;
}

.margin-horizontal-0 {
  margin-right: 0rem !important;
  margin-left: 0rem !important;
}

.margin-vertical-0 {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
}

.padding-0 {
  padding: 0rem !important;
}

.padding-top-0 {
  padding-top: 0rem !important;
}

.padding-right-0 {
  padding-right: 0rem !important;
}

.padding-bottom-0 {
  padding-bottom: 0rem !important;
}

.padding-left-0 {
  padding-left: 0rem !important;
}

.padding-horizontal-0 {
  padding-right: 0rem !important;
  padding-left: 0rem !important;
}

.padding-vertical-0 {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}

.margin-1 {
  margin: 1rem !important;
}

.margin-top-1 {
  margin-top: 1rem !important;
}

.margin-right-1 {
  margin-right: 1rem !important;
}

.margin-bottom-1 {
  margin-bottom: 1rem !important;
}

.margin-left-1 {
  margin-left: 1rem !important;
}

.margin-horizontal-1 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.margin-vertical-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.padding-1 {
  padding: 1rem !important;
}

.padding-top-1 {
  padding-top: 1rem !important;
}

.padding-right-1 {
  padding-right: 1rem !important;
}

.padding-bottom-1 {
  padding-bottom: 1rem !important;
}

.padding-left-1 {
  padding-left: 1rem !important;
}

.padding-horizontal-1 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.padding-vertical-1 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.margin-2 {
  margin: 2rem !important;
}

.margin-top-2 {
  margin-top: 2rem !important;
}

.margin-right-2 {
  margin-right: 2rem !important;
}

.margin-bottom-2 {
  margin-bottom: 2rem !important;
}

.margin-left-2 {
  margin-left: 2rem !important;
}

.margin-horizontal-2 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.margin-vertical-2 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.padding-2 {
  padding: 2rem !important;
}

.padding-top-2 {
  padding-top: 2rem !important;
}

.padding-right-2 {
  padding-right: 2rem !important;
}

.padding-bottom-2 {
  padding-bottom: 2rem !important;
}

.padding-left-2 {
  padding-left: 2rem !important;
}

.padding-horizontal-2 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.padding-vertical-2 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.margin-3 {
  margin: 3rem !important;
}

.margin-top-3 {
  margin-top: 3rem !important;
}

.margin-right-3 {
  margin-right: 3rem !important;
}

.margin-bottom-3 {
  margin-bottom: 3rem !important;
}

.margin-left-3 {
  margin-left: 3rem !important;
}

.margin-horizontal-3 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.margin-vertical-3 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.padding-3 {
  padding: 3rem !important;
}

.padding-top-3 {
  padding-top: 3rem !important;
}

.padding-right-3 {
  padding-right: 3rem !important;
}

.padding-bottom-3 {
  padding-bottom: 3rem !important;
}

.padding-left-3 {
  padding-left: 3rem !important;
}

.padding-horizontal-3 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.padding-vertical-3 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.thumbnailsProduct {
  margin: 1rem 0;
}

@media screen and (min-width: 64em) {
  .thumbnailsProduct .cell {
    min-width: calc(25% - 2rem);
  }
}
.thumbnailsProduct .thumbnailsProduct-item {
  background-color: #fff;
  border-radius: 20px;
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  height: 100%;
  overflow: hidden;
}

.thumbnailsProduct .thumbnailsProduct-item .thumbnailsProduct-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
}

.thumbnailsProduct .thumbnailsProduct-item .thumbnailsProduct-logoPartner {
  display: block;
  margin: auto;
  margin-bottom: 1.5rem;
  max-height: 3rem;
  max-width: 7rem;
  text-align: center;
}

.thumbnailsProduct .thumbnailsProduct-item .thumbnailsProduct-logoPartner img {
  height: auto;
  max-height: 100%;
  max-width: 100%;
  width: auto;
}

.thumbnailsProduct .thumbnailsProduct-item .thumbnailsProduct-img {
  border-bottom: 4px solid var(--primary-color);
  max-height: 8.5rem;
  min-height: 8.5rem;
  overflow: hidden;
}

.thumbnailsProduct .thumbnailsProduct-item .thumbnailsProduct-img.remove-border-bottom {
  border-bottom: none;
}

.thumbnailsProduct .thumbnailsProduct-item .thumbnailsProduct-img img {
  width: 100%;
}

.thumbnailsProduct .thumbnailsProduct-item .thumbnailsProduct-content {
  color: #2d2d2d;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
  padding: 1rem 1rem 1.5rem;
}

.thumbnailsProduct .thumbnailsProduct-item .thumbnailsProduct-content .thumbnailsProduct-txt {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.thumbnailsProduct .thumbnailsProduct-item .thumbnailsProduct-content .thumbnailsProduct-cta {
  text-align: center;
}

.thumbnailsProduct .thumbnailsProduct-item .thumbnailsProduct-content .thumbnailsProduct-cta--icon {
  font-size: 1.7rem;
  position: relative;
  top: 0.4rem;
}

.thumbnailsProduct .thumbnailsProduct-item:hover {
  -webkit-box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
}

.thumbnailsProduct .thumbnailsProduct-item:hover .thumbnailsProduct-cta span {
  text-decoration: underline;
}