/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.reveal.modal_validation {
  padding: 0;
}

.reveal.modal_validation[tabindex]:focus-visible {
  border: none;
}

.reveal.modal_validation .close-button {
  color: #4B4F54;
}

.reveal.modal_validation .close-button:hover {
  color: #4B4F54;
}

.reveal.modal_validation .close-button:focus-visible {
  border: 0;
  border-radius: 50%;
  width: 30px;
  height: 36px;
  outline: 3px dashed #292c2e;
}

.reveal.modal_validation .modal-validation .divider {
  border-bottom: 0.25rem solid #fafafa;
  display: block;
}

.reveal.modal_validation .modal-validation__header {
  color: #4B4F54;
}

.reveal.modal_validation .modal-validation__header h1 {
  font-weight: bold;
  padding: 0.625rem 1.25rem;
}

.reveal.modal_validation .modal-validation__body p {
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
  padding: 1.25rem;
  color: #636363;
  line-height: normal;
}

.reveal.modal_validation .modal-validation__footer-title {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0.938rem auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  gap: 10px;
}

.reveal.modal_validation .modal-validation__footer-title img {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.reveal.modal_validation .modal-validation__footer-title .description {
  line-height: 2rem;
}

.reveal.modal_validation .modal-validation__footer-title .icon-chrono {
  background: var(--primary-color);
  border-radius: 50%;
  width: 23px;
  height: 23px;
  color: white;
}

.reveal.modal_validation .modal-validation__footer-title .icon-chrono i {
  font-size: 14px;
  position: relative;
  left: 4.5px;
}

@media print, screen and (max-width: 39.99875em) {
  .reveal.modal_validation .modal-validation__footer-title {
    margin: 0.938rem 2.5rem;
  }
  .reveal.modal_validation .modal-validation__footer-title .icon-chrono {
    width: 34px;
    height: 34px;
  }
  .reveal.modal_validation .modal-validation__footer-title .icon-chrono i {
    font-size: 20px;
    position: relative;
    left: 7px;
    top: 7px;
  }
  .reveal.modal_validation .modal-validation__footer-title .description {
    line-height: 1rem;
    font-size: 1rem;
  }
}
.reveal.modal_validation .modal-validation__footer-action .button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  width: -webkit-fill-available;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0.938rem 2.5rem;
  padding: 0.75rem;
}

.reveal.modal_validation .modal-validation__footer-action .button .option {
  font-size: 18px;
}

.reveal.modal_validation .modal-validation__footer-action .button .description {
  line-height: 1.25rem;
}

.reveal.modal_validation .modal-validation__footer-action .button .description::before {
  content: "> ";
}

.reveal.modal_validation .modal-validation__footer-action .poursuivre {
  background: var(--primary-color);
  color: white;
}

.reveal.modal_validation .modal-validation__footer-action .poursuivre:hover {
  background: #1D602A;
}

.reveal.modal_validation .modal-validation__footer-action .fermeture {
  background: white;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.reveal.modal_validation .modal-validation__footer-action .fermeture:hover {
  background: #EFF8F0;
  color: #1D602A;
  border: 1px solid #1D602A;
}