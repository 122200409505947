/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
footer {
  padding-top: 1rem;
}

footer .nav-title {
  color: var(--primary-color);
  font-weight: bold;
  font-size: 0.875rem;
  margin-bottom: 0;
}

footer ul {
  margin-left: 0;
  list-style: none;
}

footer ul li {
  margin: 0.3rem 0;
}

footer ul li a {
  font-size: 0.875rem;
  color: #2d2d2d;
}

footer .footer-bottom {
  padding-bottom: 3rem;
}

footer .footer-bottom ul li {
  padding: 0 1rem;
  position: relative;
  color: #8a8a8a;
  font-weight: bold;
}

footer .footer-bottom ul li a {
  color: #8a8a8a;
}

@media print, screen and (min-width: 30em) {
  footer .footer-bottom ul li:not(:last-child):after {
    content: "|";
    position: absolute;
    right: 0;
  }
}
@media print, screen and (max-width: 29.99875em) {
  footer .footer-bottom ul {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  footer .footer-bottom ul > li {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  footer .footer-bottom ul li {
    padding: 0;
  }
}