/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.caroussel {
  min-width: 20.38rem;
}

.caroussel .caroussel_green-mode .caroussel-item {
  border: 1px solid #268038;
}

.caroussel .caroussel_green-mode .slick-prev, .caroussel .caroussel_green-mode .slick-next {
  background-color: #268038;
}

.caroussel .caroussel_green-mode .slick-dots li button .slick-dot-icon:before {
  color: #268038;
}

.caroussel .caroussel_purple-mode .caroussel-item {
  border: 1px solid #EFDBEB;
}

.caroussel .caroussel_purple-mode .slick-prev, .caroussel .caroussel_purple-mode .slick-next {
  background-color: #9d5190;
}

.caroussel .caroussel_purple-mode .slick-dots li button .slick-dot-icon:before {
  color: #9d5190;
}

.caroussel__banner {
  margin: 1.5rem 0;
}

@media screen and (min-width: 48em) {
  .caroussel__banner {
    margin: 2rem 0;
  }
}
.caroussel__slider {
  border-radius: 1rem;
  padding: 1rem;
  height: 100%;
  width: 100%;
}

.caroussel-item {
  border-radius: 1rem;
  border: 1px solid #B4DFF0;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  padding: 1.5rem;
  width: 22rem;
  text-align: left;
}

@media print, screen and (min-width: 30em) {
  .caroussel-item {
    min-width: 20.375rem;
  }
}
.caroussel-item img {
  margin-bottom: 1.25rem;
  height: 48px;
  width: 48px;
}

@media print, screen and (max-width: 39.99875em) {
  .caroussel-item__title, .caroussel-item__desc {
    font-size: 0.875rem;
  }
}
.caroussel-item__title {
  margin-bottom: 0.5rem;
}

@media print, screen and (min-width: 30em) {
  .caroussel-item__desc p {
    margin-bottom: 0;
  }
}
.caroussel .caroussel-item {
  overflow-wrap: break-word;
}

.caroussel .slick-list {
  margin-bottom: 1rem;
}

@media print, screen and (min-width: 30em) {
  .caroussel .slick-list {
    margin-bottom: 2rem;
  }
}
.caroussel .slick-dotted {
  margin-bottom: 0.5rem;
}

.caroussel .slick-arrow {
  height: 2rem;
  width: 2rem;
}

@media print, screen and (max-width: 39.99875em) {
  .caroussel .slick-arrow {
    height: 2rem;
    width: 2rem;
  }
}
.caroussel .slick-arrow .slider-arrow {
  font-size: 1rem;
}

.caroussel .slick-prev {
  left: 0;
}

.caroussel .slick-next {
  right: 0;
}

.caroussel .slick-disabled {
  opacity: 0;
}

.caroussel .slick-prev, .caroussel .slick-next {
  background-color: #007590;
  border-radius: 50%;
  color: white;
  -webkit-transform: none;
          transform: none;
  z-index: 1;
  top: calc(50% - 2.2rem);
}

.caroussel .slick-slide {
  padding: 0 1rem;
  height: inherit;
}

.caroussel .slick-track, .caroussel .slick-slide, .caroussel .slick-slide > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: stretch;
      -ms-flex-pack: stretch;
          justify-content: stretch;
  width: 100%;
}

.caroussel .slick-dots {
  bottom: 4rem;
  width: 40px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
}

@media print, screen and (min-width: 30em) {
  .caroussel .slick-dots {
    bottom: 1.5rem;
  }
}
.caroussel .slick-dots li {
  width: 10px;
  height: 10px;
}

.caroussel .slick-dots li button {
  width: 10px;
  height: 10px;
}

.caroussel .slick-dots li button .slick-dot-icon:before {
  color: #007590;
  width: 10px;
  height: 10px;
  font-size: 32px;
}

.caroussel .slick-dots li.slick-active button .slick-dot-icon:before {
  margin-top: 0px;
  margin-left: 0px;
}