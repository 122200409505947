/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.list-cards-component {
  margin-top: 3rem;
  margin-bottom: 1rem;
}

@media print, screen and (min-width: 30em) {
  .list-cards-component {
    margin-bottom: 3rem;
  }
}
.list-cards-component .grid-margin-y > .cell {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.list-cards-component .grid-margin-x > .cell {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

@media screen and (min-width: 64em) {
  .list-cards-component {
    min-width: 47.5rem;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .list-cards-component .grid-margin-y > .cell {
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
.list-cards-component-item {
  border-radius: 1rem;
  padding: 1rem;
  width: 50%;
  background-color: #E8F5F3;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  border-radius: 1rem;
  padding: 1rem;
}

@media print, screen and (min-width: 30em) {
  .list-cards-component-item {
    height: 16rem;
    width: 33%;
    border-radius: 2rem;
    padding: 2rem;
  }
}
@media screen and (min-width: 48em) {
  .list-cards-component-item {
    width: 21.5rem;
    height: 21.5rem;
  }
}
.list-cards-component-item__title {
  font-size: 0.75rem;
  margin-top: 0.25rem;
}

@media print, screen and (min-width: 30em) {
  .list-cards-component-item__title {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }
}
.list-cards-component-item__box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
  font-size: 0.875rem;
}

@media print, screen and (min-width: 30em) {
  .list-cards-component-item__box {
    font-size: 24px;
  }
}
.list-cards-component picture {
  height: 6rem;
  width: 6rem;
  place-self: center;
  margin-bottom: 1rem;
}

@media print, screen and (max-width: 47.99875em) {
  .list-cards-component picture {
    height: 2.5rem;
    width: 2.5rem;
  }
}
.list-cards-component p {
  margin: 0;
  width: 100%;
}