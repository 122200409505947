/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.photo-block {
  margin: 2rem 0;
}

@media print, screen and (max-width: 39.99875em) {
  .photo-block .grid-x {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column wrap;
            flex-flow: column wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
.photo-block .grid-container {
  position: relative;
}

@media print, screen and (max-width: 39.99875em) {
  .photo-block_img {
    width: 100%;
  }
}
.photo-block_textblock {
  background-color: #fff;
  -webkit-box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);
  padding: 2rem 2rem 2rem 3rem;
  position: absolute;
  top: 2.5rem;
  width: 50%;
}

@media print, screen and (max-width: 39.99875em) {
  .photo-block_textblock {
    left: 0;
    margin-top: 0;
    position: inherit;
    right: 0;
    top: 0;
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    width: 80%;
  }
}
.photo-block_textblock--title {
  font-size: 1.25rem;
  position: relative;
}

@media print, screen and (max-width: 39.99875em) {
  .photo-block_textblock--title {
    font-size: initial;
  }
}
.photo-block_textblock--title::before {
  background-color: var(--primary-color);
  border-radius: 4px;
  content: "";
  display: block;
  height: calc(100% + 8px);
  left: -20px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 4px;
}

.photo-block_textblock--description {
  color: #8a8a8a;
  margin-bottom: 0;
}

.photo-block_textblock--button .button {
  margin-bottom: 0;
  margin-top: 2rem;
}

.photo-block .grid-x.align-left .photo-block_textblock {
  right: 1rem;
}

.photo-block .grid-x.align-right .photo-block_textblock {
  left: 1rem;
}