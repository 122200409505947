/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.comp-document-to-download {
  background-color: #f2f3f3;
  margin: 2rem 0;
  padding: 0.5rem 2.5rem;
}

.comp-document-to-download__text ul {
  margin-bottom: 1rem;
}

.comp-document-to-download__text ul li {
  list-style-type: disc;
}

.comp-document-to-download__text ul li::before {
  content: none;
}

.comp-document-to-download__downloads {
  padding-top: 1.5rem;
}

.comp-document-to-download__downloads .download-list {
  margin-left: 0;
}

.comp-document-to-download__downloads .download-list li:not(:first-child) {
  margin-top: 0.5rem;
}

.comp-document-to-download__downloads .document-download-container {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #fff;
  color: #292c2e;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  padding: 0.5rem 2.5rem 0.5rem 1rem;
}

@media print, screen and (max-width: 39.99875em) {
  .comp-document-to-download__downloads .document-download-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    text-align: center;
  }
  .comp-document-to-download__downloads .document-download-container i {
    margin-bottom: 0.5rem;
    margin-right: 0;
  }
}
.comp-document-to-download__downloads .document-download-container i {
  font-size: 2rem;
  margin-right: 1rem;
}

.comp-document-to-download__downloads .document-download-container span {
  text-decoration: underline;
}