/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.bubble-banner-comp {
  -webkit-box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);
  margin: 5rem 0 2rem;
  padding: 1rem;
  position: relative;
}

@media print, screen and (min-width: 40em) {
  .bubble-banner-comp {
    padding: 6.25rem 0.625rem 2rem 2.5rem;
  }
}
@media screen and (min-width: 64em) {
  .bubble-banner-comp {
    padding: 4.5rem 3rem 2rem;
  }
}
.bubble-banner-comp__illustration {
  max-height: 100%;
  position: absolute;
  right: 0.5rem;
  top: -8%;
  width: 5.625rem;
  z-index: -1;
}

@media print, screen and (min-width: 40em) {
  .bubble-banner-comp__illustration {
    bottom: 0%;
    left: 1rem;
    top: unset;
    width: unset;
  }
}
.bubble-banner-comp__catchword {
  background-color: var(--primary-color);
  border-radius: 5px;
  color: #fff;
  display: none;
  font-style: italic;
  font-weight: bold;
  padding: 1rem;
  position: absolute;
}

@media print, screen and (min-width: 40em) {
  .bubble-banner-comp__catchword {
    display: block;
  }
}
.bubble-banner-comp__catchword.word0 {
  right: 41%;
  top: -15%;
}

.bubble-banner-comp__catchword.word1 {
  right: 27%;
  top: -5%;
}

.bubble-banner-comp__catchword.word2 {
  right: 3%;
  top: -13%;
}

.bubble-banner-comp__catchword.word3 {
  right: 0%;
  top: 14%;
}

.bubble-banner-comp__catchword::after {
  border-bottom: 20px solid var(--primary-color);
  border-left: 5px solid transparent;
  border-right: 15px solid transparent;
  bottom: -10px;
  content: "";
  height: 0;
  left: 50px;
  position: absolute;
  -webkit-transform: rotate(-270deg);
          transform: rotate(-270deg);
  width: 0;
}

.bubble-banner-comp__content__textblock {
  margin-bottom: 2rem;
}

@media print, screen and (min-width: 40em) {
  .bubble-banner-comp__content__textblock {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 3rem;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.bubble-banner-comp__content__textblock > div {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 0px;
          flex: 1 1 0px;
  max-width: calc(100% - 6.25rem);
}

@media print, screen and (min-width: 40em) {
  .bubble-banner-comp__content__textblock > div {
    max-width: 11rem;
  }
}
@media screen and (min-width: 48em) {
  .bubble-banner-comp__content__textblock > div {
    max-width: 14rem;
  }
}
@media screen and (min-width: 64em) {
  .bubble-banner-comp__content__textblock > div {
    max-width: 18rem;
  }
}
.bubble-banner-comp__content__textblock--title {
  position: relative;
}

.bubble-banner-comp__content__textblock--title h2 {
  font-size: 1.5rem;
  margin: 0;
}

@media screen and (min-width: 48em) {
  .bubble-banner-comp__content__textblock--title h2 {
    font-size: 2rem;
  }
}
@media print, screen and (min-width: 40em) {
  .bubble-banner-comp__content__textblock--title::after {
    background-color: var(--primary-color);
    border-radius: 4px;
    content: "";
    display: block;
    height: calc(100% + 8px);
    position: absolute;
    right: calc(-1.5rem - 2px);
    top: -4px;
    width: 4px;
  }
}
.bubble-banner-comp__content__textblock--description {
  font-size: 0.825rem;
  position: relative;
}

.bubble-banner-comp__content__textblock--description p {
  margin: 0;
}

@media print, screen and (min-width: 40em) {
  .bubble-banner-comp__content__textblock--description::before {
    background-color: var(--primary-color);
    border-radius: 4px;
    content: "";
    display: block;
    height: calc(100% + 8px);
    left: calc(-1.5rem - 2px);
    position: absolute;
    top: -4px;
    width: 4px;
  }
}