@charset "UTF-8";
/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.accordion-component {
  margin: 2rem 0;
}

.accordion-component .accordion .accordion-item:first-child .accordion-title {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.accordion-component .accordion .accordion-item:last-child .accordion-title {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.accordion-component .accordion .accordion-item.is-active:last-child .accordion-title {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.accordion-component .accordion .accordion-item.is-active:last-child .accordion-content {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.accordion-component .accordion .accordion-title {
  font-weight: bold;
  line-height: 1.5;
}

@media print, screen and (max-width: 39.99875em) {
  .accordion-component .accordion .accordion-title {
    padding: 1rem 2.5rem 1rem 1.5rem;
  }
  .accordion-component .accordion .accordion-title::before {
    right: 1rem;
  }
}
.accordion-component .accordion .accordion-title::before {
  font-size: 1rem;
  margin-top: -0.75rem;
  right: 2rem;
}

.accordion-component .accordion .accordion-title:hover, .accordion-component .accordion .accordion-title:focus {
  color: #2d2d2d;
}

.accordion-component .accordion .accordion-content {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

@media print, screen and (max-width: 39.99875em) {
  .accordion-component .accordion .accordion-content {
    padding: 1rem 1.5rem;
  }
}
.accordion-component .accordion .accordion-content dl dt {
  padding-left: 1.25em;
  position: relative;
}

.accordion-component .accordion .accordion-content dl dt::before {
  content: "•";
  font-size: 1.3em;
  left: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.accordion-component .accordion .accordion-content dl dd {
  margin-bottom: 1.5em;
}

.accordion-component .accordion .accordion-content ul {
  margin: 0;
}

.accordion-component .accordion .accordion-content ul li {
  padding-left: 1.5em;
  position: relative;
}

.accordion-component .accordion .accordion-content ul li::before {
  content: "•";
  font-size: 1.3em;
  left: 0;
  line-height: 1;
  position: absolute;
  top: 3px;
}