/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
._credit-simulator {
  margin-bottom: 1rem;
  position: sticky;
  top: 8rem;
}

.credit-simulator {
  width: 100%;
}

@media print, screen and (max-width: 47.99875em) {
  .credit-simulator {
    bottom: 0;
    left: 0;
    position: fixed;
    z-index: 60;
  }
}
.credit-simulator__frame {
  background-color: #fff;
  border-radius: 1.5rem;
  -webkit-box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);
  max-width: 20rem;
  padding: 1rem 1.5rem;
  position: relative;
  text-align: center;
  z-index: 60;
}

@media print, screen and (max-width: 47.99875em) {
  .credit-simulator__frame {
    border-radius: 0;
    -webkit-box-shadow: unset;
            box-shadow: unset;
    max-width: unset;
  }
}
@media screen and (min-width: 48em) {
  .credit-simulator__frame.hide {
    display: block !important;
  }
}
.credit-simulator h2 {
  color: var(--primary-color);
  font-size: 1;
}

.credit-simulator label {
  margin-bottom: 0.5rem;
}

.credit-simulator .text-small {
  color: #8a8a8a;
  font-size: 0.75rem;
}

.credit-simulator__amount {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.credit-simulator__amount__input {
  font-weight: bold;
  position: relative;
  width: 100%;
}

.credit-simulator__amount__input input {
  -webkit-appearance: textfield;
     -moz-appearance: textfield;
          appearance: textfield;
  background-color: #f2f3f3;
  border-radius: 0.5rem;
  border-width: 0;
  -webkit-box-shadow: unset;
          box-shadow: unset;
  font-size: 1.5rem;
  font-weight: bold;
  height: unset;
  line-height: unset;
  margin: 0;
  padding: 0.5rem;
  text-align: center;
  width: 100%;
  color: #4B4F54;
}

.credit-simulator__amount__input input::-webkit-outer-spin-button, .credit-simulator__amount__input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
          appearance: none;
  margin: 0;
}

.credit-simulator__amount__input input:focus-visible {
  outline: unset;
}

.credit-simulator__amount__input input:focus {
  background-color: #f2f3f3;
  border-width: 0;
  -webkit-box-shadow: initial;
          box-shadow: initial;
}

.credit-simulator__amount__input .euro-symbol {
  font-size: 1.5rem;
  position: absolute;
  right: 0.5rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.credit-simulator__amount__text.error {
  color: #c8102e;
}

.credit-simulator__button {
  background-color: #cacaca;
  border-radius: 0.5rem;
  color: #fff;
  cursor: pointer;
  display: block;
  font-weight: bold;
  margin-bottom: 1rem;
  padding: 15px 30px;
  pointer-events: none;
  text-transform: uppercase;
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
  width: 100%;
}

.credit-simulator__button:hover {
  color: #fff;
}

.credit-simulator__button.active {
  background-color: var(--primary-color);
  color: #fff;
  pointer-events: initial;
}

.credit-simulator__footer {
  margin-bottom: 0;
}

.credit-simulator__mobileaccess {
  background-color: #fff;
  -webkit-box-shadow: 0 -10px 10px -10px #999;
          box-shadow: 0 -10px 10px -10px #999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 1rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0.4rem 0;
  position: relative;
  z-index: 60;
}

.credit-simulator__mobileaccess__button {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: var(--primary-color);
  color: #fff;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: bold;
  gap: 0.5rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0.8rem;
  width: calc(100% - 4rem);
}

.credit-simulator__mobileaccess__button i {
  font-size: 1.5rem;
}

.credit-simulator__overlay {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 50;
}