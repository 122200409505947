/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.columners {
  margin: 2rem 0;
}

.columners > .grid-x {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.columners_cell {
  padding: 0 1rem;
}

.columners.hasborders .columners_cell:not(:last-child) {
  border-right: 1px solid #d9d9d9;
}

@media print, screen and (max-width: 39.99875em) {
  .columners {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column wrap;
            flex-flow: column wrap;
  }
  .columners .columners_cell {
    margin-bottom: 1rem;
  }
  .columners.hasborders .columners_cell:not(:last-child) {
    border: none;
  }
}