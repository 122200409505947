/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.univers-link {
  font-weight: bold;
  margin: 2rem 0;
}

.univers-link--desktop {
  display: none;
}

@media print, screen and (min-width: 30em) {
  .univers-link--desktop {
    display: initial;
  }
}
.univers-link--desktop .univers-link__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.univers-link--desktop .univers-link__item {
  position: relative;
}

.univers-link--desktop .univers-link__item:hover::after, .univers-link--desktop .univers-link__item.active::after {
  background-color: var(--primary-color);
  border-radius: 3px 3px 0 0;
  content: "";
  display: block;
  height: 3px;
  left: 0;
  position: absolute;
  top: 100%;
  width: 100%;
}

.univers-link--desktop .univers-link__item:not(:last-child) {
  border-right: 1px solid #c3c3c3;
}

.univers-link--desktop .univers-link__item a {
  color: #292c2e;
  display: block;
  height: 100%;
  line-height: 1.2;
  padding: 0 0.75rem;
  width: 100%;
}

.univers-link--mobile {
  display: initial;
}

@media print, screen and (min-width: 30em) {
  .univers-link--mobile {
    display: none;
  }
}
.univers-link--mobile .univers-link__selector {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: solid 1px #c3c3c3;
  border-radius: 0;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: bold;
  height: 2.5rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  width: 100%;
}

.univers-link--mobile .univers-link__selector .chevron {
  position: absolute;
  right: 1rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.univers-link--mobile .univers-link__link-list {
  border: solid 1px #c3c3c3;
  border-top-width: 0;
  margin: 0;
}

.univers-link--mobile .univers-link__link-list li a {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #292c2e;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 2.5rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.univers-link--mobile .univers-link__link-list li:not(:first-child) {
  border-top: solid 1px #c3c3c3;
}