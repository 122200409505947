/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.mascot-banner {
  margin: 4rem 0 2rem 0;
  text-align: center;
}

@media print, screen and (max-width: 39.99875em) {
  .mascot-banner {
    margin: 2rem 0 0 0;
  }
}
.mascot-banner .text-container {
  background-color: var(--background-color);
  border-radius: 2rem;
  padding: 2.5rem;
  position: relative;
}

@media print, screen and (max-width: 39.99875em) {
  .mascot-banner .text-container {
    padding: 1.5rem;
  }
}
.mascot-banner .text-container .hero-image {
  height: 130px;
  left: 50%;
  position: absolute;
  top: -119px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 195px;
}

.mascot-banner .text-container p {
  margin-bottom: 0;
}

.mascot-banner .text-container h3 {
  font-size: 2rem;
  line-height: 2.5rem;
  margin-bottom: 1.5rem;
}

@media print, screen and (max-width: 39.99875em) {
  .mascot-banner .text-container h3 {
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin-bottom: 0.5rem;
  }
}
.mascot-banner .text-container a {
  margin-bottom: 0;
  margin-top: 2rem;
}

@media print, screen and (max-width: 39.99875em) {
  .mascot-banner .text-container a {
    margin-top: 1rem;
  }
}