/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.button-sticky {
  width: 100%;
}

@media print, screen and (max-width: 47.99875em) {
  .button-sticky {
    bottom: 0;
    position: fixed;
    text-align: center;
    z-index: 999;
  }
}
.button-sticky_mobileaccess {
  background-color: #fff;
  -webkit-box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.5);
          box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.5);
  padding: 7px 0;
}

.button-sticky_mobileaccess--button {
  background-color: var(--primary-color);
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  width: calc(100% - 4rem);
  border-radius: 5px;
  line-height: 19px;
  margin-bottom: 2px;
}

.button-sticky_mobileaccess--text {
  font-size: 12px;
  line-height: 22px;
  color: #213B25;
  margin-bottom: 0;
  padding: 0 10px;
}