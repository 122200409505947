/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.go-to-top__button::before {
  font-family: pf-icon !important;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: never;
  text-transform: none;
}

.go-to-top__button::before {
  content: "\e938";
}

.go-to-top {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin: 2rem 0;
}

@media print, screen and (max-width: 39.99875em) {
  .go-to-top {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.go-to-top__button {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media print, screen and (max-width: 39.99875em) {
  .go-to-top__button {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.go-to-top__button::before {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: var(--primary-color);
  border-radius: 50%;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 2rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  line-height: 2rem;
  margin: 0 0.5rem;
  width: 2rem;
}

@media print, screen and (max-width: 39.99875em) {
  .go-to-top__button::before {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin: 0 0 0.5rem;
  }
}