/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.full-background {
  background-color: #EFF4F2;
  padding: 40px 0;
}

@media print, screen and (max-width: 63.99875em) {
  .full-background {
    background-color: #fff;
    padding: 0;
  }
}
@media print, screen and (max-width: 29.99875em) {
  .full-background {
    display: none;
  }
}
.credit-simulator-dt {
  background-color: #fff;
  border-radius: 0.25rem;
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  text-align: left;
  position: relative;
  width: 546px;
  margin: auto;
}

@media print, screen and (max-width: 63.99875em) {
  .credit-simulator-dt {
    background-color: #EFF4F2;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .credit-simulator-dt {
    padding: 1rem;
    width: 100%;
  }
}
.credit-simulator-dt .text-header {
  margin: 0;
}

.credit-simulator-dt .text-subtitle {
  margin: 0;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

@media print, screen and (max-width: 39.99875em) {
  .credit-simulator-dt .text-subtitle {
    font-size: 1rem;
    line-height: 1.625rem;
    text-align: center;
  }
}
.credit-simulator-dt__footer {
  font-size: 1rem;
  line-height: 1.5rem;
  color: #4B4F54;
  margin: 0;
}

@media print, screen and (max-width: 29.99875em) {
  .credit-simulator-dt__footer {
    text-align: center;
  }
}
.credit-simulator-dt__footer p {
  margin: 0;
}

.credit-simulator-dt__amount {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.credit-simulator-dt__amount__text {
  color: #4B4F54;
  padding-top: 0.25rem;
}

.credit-simulator-dt__amount__text.error {
  color: #c8102e;
}

@media print, screen and (max-width: 39.99875em) {
  .credit-simulator-dt__amount__text {
    text-align: center;
  }
}
.credit-simulator-dt__amount input#credit-simulator-value--slider {
  -webkit-appearance: textfield;
     -moz-appearance: textfield;
          appearance: textfield;
  background-color: #fff;
  outline: 1px solid #6F757C;
  color: #4B4F54;
  border-width: 0;
  border-radius: 0.25rem;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 2rem;
  padding: 0.5rem 1rem;
  text-align: center;
  width: 100%;
  color: #4B4F54;
  margin: 0;
  height: auto;
}

.credit-simulator-dt__amount input#credit-simulator-value--slider::-webkit-input-placeholder {
  color: #4B4F54;
}

.credit-simulator-dt__amount input#credit-simulator-value--slider::-moz-placeholder {
  color: #4B4F54;
}

.credit-simulator-dt__amount input#credit-simulator-value--slider:-ms-input-placeholder {
  color: #4B4F54;
}

.credit-simulator-dt__amount input#credit-simulator-value--slider::-ms-input-placeholder {
  color: #4B4F54;
}

.credit-simulator-dt__amount input#credit-simulator-value--slider::placeholder {
  color: #4B4F54;
}

.credit-simulator-dt #credit-simulator-button--slider {
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0;
  padding: 0.75rem 0.25rem;
  border-radius: 0.25rem;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  background-color: #cacaca;
  cursor: pointer;
  pointer-events: none;
  text-transform: none;
}

.credit-simulator-dt #credit-simulator-button--slider.active {
  background-color: var(--primary-color);
  color: #fff;
  pointer-events: initial;
}

.credit-simulator-dt__form {
  gap: 0.5rem;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin: 1rem 0 0.5rem 0;
}

@media print, screen and (max-width: 63.99875em) {
  .credit-simulator-dt__form {
    gap: 1rem;
  }
}