/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.toolbox {
  background-color: #e6e6e6;
  padding: 0.7rem 0;
}

.toolbox-content .grid-x {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.toolbox-content .toolbox__title {
  color: var(--primary-color);
}

@media print, screen and (max-width: 39.99875em) {
  .toolbox-content .toolbox__title {
    display: inline-block;
    font-size: 0.85rem;
    margin-bottom: 0.5rem;
  }
}
.toolbox-content a {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #292c2e;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 0.95rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

@media print, screen and (max-width: 29.99875em) {
  .toolbox-content a {
    font-size: 0.75rem;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    margin-bottom: 1rem;
  }
}
.toolbox-content li:last-child a {
  margin-bottom: 0;
}

.toolbox-content i {
  font-size: 1.5em;
  margin-right: 0.6rem;
}