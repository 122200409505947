/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.sticky-bar {
  position: fixed !important;
  right: 0rem !important;
  bottom: 0rem !important;
  left: 0rem !important;
  z-index: 9;
  -webkit-box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.2);
  background-color: #fff;
}

@media print, screen and (max-width: 39.99875em) {
  .sticky-bar {
    display: none;
  }
}
.sticky-bar__grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: auto 1fr;
}

.sticky-bar__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  gap: 1rem;
  margin-left: 0;
}

.sticky-bar__list i {
  width: 26px;
  height: 26px;
  border-radius: 50% !important;
  background-color: #292c2e;
  color: #fff;
  display: block;
  font-size: 1rem;
  position: relative;
}

.sticky-bar__list i::before {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.sticky-bar__list li:hover {
  opacity: 0.8;
}

.sticky-bar__list--rs {
  gap: 2rem;
}

@media print, screen and (max-width: 63.99875em) {
  .sticky-bar__list--rs {
    gap: 0.5rem;
  }
}
.sticky-bar__list--contacts {
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.sticky-bar__list--contacts li {
  width: auto;
}

.sticky-bar__list--contacts a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 0.5rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

@media print, screen and (max-width: 63.99875em) {
  .sticky-bar__list--contacts a span {
    font-size: 0.7rem;
    text-align: center;
  }
}
.sticky-bar__list--contacts a i {
  background-color: var(--primary-color);
}

.sticky-bar__list--contacts a span {
  color: #292c2e;
  font-size: 0.8rem;
  font-weight: bold;
  text-transform: uppercase;
}