/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
ul.skip-link {
  position: fixed;
  top: 2px;
  left: 0;
  z-index: 101;
  margin-left: 0;
}

ul.skip-link li {
  display: inline;
  white-space: nowrap;
}

ul.skip-link li a {
  position: absolute;
  left: -99999rem;
  padding: 0.5rem 0.8rem;
}

ul.skip-link li a:focus {
  left: 0;
  color: #fff;
  background-color: #8a8a8a;
}

@media screen and (max-width: 768px) {
  ul.skip-link {
    display: none;
  }
}