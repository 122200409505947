/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.hero-banner .hero-banner-breadcrumb .breadcrumb-item:not(:last-child) span::before {
  font-family: pf-icon !important;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: never;
  text-transform: none;
}

.hero-banner .hero-banner-breadcrumb .breadcrumb-item:not(:last-child) span::before {
  content: "\e937";
}

.hero-banner {
  margin-bottom: 1rem;
  position: relative;
}

.hero-banner.hero-banner-image {
  height: 15rem;
  overflow: hidden;
}

@media print, screen and (max-width: 39.99875em) {
  .hero-banner.hero-banner-image {
    height: auto;
  }
}
.hero-banner.hero-banner-image picture {
  display: block;
  height: 100%;
  width: 100%;
}

@media print, screen and (max-width: 39.99875em) {
  .hero-banner.hero-banner-image picture {
    display: none;
  }
}
.hero-banner.hero-banner-image picture img {
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
}

.hero-banner.hero-banner-image .hero-banner-breadcrumb {
  margin: 0 2rem;
  position: absolute;
  top: 0.6rem;
}

.hero-banner.hero-banner-image .hero-banner-breadcrumb .breadcrumb-list .breadcrumb-item {
  background-color: rgba(245, 245, 245, 0.5);
}

.hero-banner.hero-banner-image .hero-banner-title {
  bottom: 1rem;
  position: absolute;
}

@media print, screen and (max-width: 39.99875em) {
  .hero-banner.hero-banner-image .hero-banner-title {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin: 1rem 0;
    position: relative;
  }
}
.hero-banner .hero-banner-breadcrumb {
  margin: 0.6rem 2rem;
  position: relative;
}

@media print, screen and (max-width: 39.99875em) {
  .hero-banner .hero-banner-breadcrumb {
    display: none;
  }
}
.hero-banner .hero-banner-breadcrumb .breadcrumb-list {
  margin: 0;
}

.hero-banner .hero-banner-breadcrumb .breadcrumb-item {
  font-size: 0.75rem;
  line-height: 0.75rem;
  list-style: none;
  padding: 0.2rem;
  position: relative;
}

.hero-banner .hero-banner-breadcrumb .breadcrumb-item:last-child {
  pointer-events: none;
}

.hero-banner .hero-banner-breadcrumb .breadcrumb-item:not(:last-child) span::before {
  margin: auto;
  position: absolute;
  right: 0.315rem;
}

.hero-banner .hero-banner-breadcrumb .breadcrumb-item:not(:last-child) .item {
  padding-right: 1.25rem;
}

.hero-banner .hero-banner-breadcrumb .breadcrumb-item .item {
  color: #2d2d2d;
}

.hero-banner .hero-banner-title {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 500px;
  margin: 3rem 2rem 0;
  position: relative;
}

@media print, screen and (max-width: 39.99875em) {
  .hero-banner .hero-banner-title {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin: 1rem 0;
    position: relative;
  }
}
.hero-banner .hero-banner-title .hero-banner-picto {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: var(--primary-color);
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 3.125rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  left: -0.3125rem;
  min-height: 3.125rem;
  min-width: 3.125rem;
  padding: 0.5rem;
  position: absolute;
  top: -0.3125rem;
  width: 3.125rem;
}

@media print, screen and (max-width: 39.99875em) {
  .hero-banner .hero-banner-title .hero-banner-picto {
    left: 0;
    position: relative;
    top: 0;
  }
  .hero-banner .hero-banner-title .hero-banner-picto img {
    display: block;
  }
}
.hero-banner .hero-banner-title .hero-banner-picto .icon {
  color: #fff;
  font-size: 2rem;
}

.hero-banner .hero-banner-title .title {
  color: var(--primary-color);
  font-weight: bold;
  line-height: 2.5rem;
  padding: 0 2rem 0 4rem;
}

@media print, screen and (max-width: 39.99875em) {
  .hero-banner .hero-banner-title .title {
    padding: 0 0 0 1rem;
  }
}