/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.header-simulator {
  height: 66px;
  left: 0;
  margin: auto;
  position: fixed;
  right: 0;
  width: 100%;
}

@media print, screen and (max-width: 47.99875em) {
  .header-simulator {
    height: 50px;
  }
}
.header-simulator .header-container {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 5em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  height: calc(66px - 0.25em);
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 0.25em;
  padding: 0 1em;
}

@media print, screen and (max-width: 47.99875em) {
  .header-simulator .header-container {
    height: calc(50px - 0.25em);
  }
}
.header-simulator .header-container .logo-container,
.header-simulator .header-container .logo-partner {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}

.header-simulator .header-container .logo-container img,
.header-simulator .header-container .logo-container a,
.header-simulator .header-container .logo-partner img,
.header-simulator .header-container .logo-partner a {
  height: auto;
  max-height: calc(66px - 0.25em);
}

@media print, screen and (max-width: 47.99875em) {
  .header-simulator .header-container .logo-container img,
  .header-simulator .header-container .logo-container a,
  .header-simulator .header-container .logo-partner img,
  .header-simulator .header-container .logo-partner a {
    max-height: calc(50px - 0.25em);
  }
}
.header-simulator .header-container .msg-partners {
  border-bottom: 1px solid #2d2d2d;
  color: #2d2d2d;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin: 0 1em;
  text-align: right;
}

@media print, screen and (max-width: 47.99875em) {
  .header-simulator .header-container .msg-partners {
    display: none;
  }
}
.header-simulator .header-container .msg-partners p {
  margin-bottom: 0;
}