/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.modal-container {
  max-width: 34rem;
  padding: 3.625rem 1.5rem 1.5rem;
  position: relative;
  top: 3.75rem;
}

@media print, screen and (max-width: 39.99875em) {
  .modal-container {
    height: 100%;
    margin: 0;
    max-width: 100%;
    min-width: 18.75rem;
    padding: 3.5rem 1rem 1rem;
    width: 100%;
  }
}
.modal-container .close-button {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 50%;
  -webkit-box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.15);
          box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.15);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 1.25rem;
  font-weight: bold;
  height: 1.75rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 1.125rem 1.125rem 0.75rem;
  margin-top: 1rem;
  right: 0;
  top: 0;
  width: 1.75rem;
}

.modal-container .close-button i {
  color: #4B4F54;
  font-size: 1.5rem;
}

.modal.green-mode ol li::before {
  background-color: #268038;
}

.modal.green-mode .modal__close .button {
  background-color: #268038;
}

.modal.green-mode .modal__close .button:hover {
  background-color: #268038;
}

.modal.purple-mode ol li::before {
  background-color: #9d5190;
}

.modal.purple-mode .modal__close .button {
  background-color: #9d5190;
}

.modal.purple-mode .modal__close .button:hover {
  background-color: #9d5190;
}

.modal ol {
  counter-reset: list;
  list-style-position: outside;
  list-style-type: none;
  margin-left: 0;
}

.modal ol li {
  margin-bottom: 1.5rem;
  padding: 3px 0.188rem 0 2.5rem;
}

.modal ol li::before {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #007590;
  border-radius: 50%;
  color: #fff;
  content: counter(list);
  counter-increment: list;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 0.875rem;
  font-weight: bold;
  height: 2rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  left: 1rem;
  line-height: 1.25rem;
  position: absolute;
  text-align: center;
  width: 2rem;
}

.modal__close {
  position: relative;
  text-align: right;
}

.modal__close .button {
  background-color: #007590;
  border: 0;
  line-height: 1.5rem;
  margin: 0;
  padding: 0.75rem 1rem;
}

@media print, screen and (max-width: 39.99875em) {
  .modal__close .button {
    width: 100%;
  }
}
.modal__close .button:hover {
  background-color: #007590;
}