/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.comp-message-banner {
  background-color: #fff;
  font-size: 1.12rem;
  left: 0;
  line-height: 1.68rem;
  padding: 0.375rem 0;
  position: fixed;
  text-align: center;
  top: 66px;
  width: 100%;
  z-index: 90;
  border-bottom: 1px solid #d9d9d9;
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}

@media print, screen and (max-width: 39.99875em) {
  .comp-message-banner {
    font-size: 0.9rem;
    line-height: 1.12rem;
    top: 50px;
  }
}