/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
header .headercontainer .mobileNav_container .mobileConnection {
  font-family: pf-icon !important;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: never;
  text-transform: none;
}

header .headercontainer .mobileNav_container .mobileConnection::before {
  content: "\e9cf";
}

header .navmobile_container .navmobile_item.search-mob .search-bar__input .inbenta-autocomplete-header .autocomplete-list a::before, header .headercontainer nav:not(.navmobile_container) ul .navtab .subnavigation .search-bar__input .inbenta-autocomplete-header .autocomplete-list a::before {
  font-family: pf-icon !important;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: never;
  text-transform: none;
}

header .navmobile_container .navmobile_item.search-mob .search-bar__input .inbenta-autocomplete-header .autocomplete-list a::before, header .headercontainer nav:not(.navmobile_container) ul .navtab .subnavigation .search-bar__input .inbenta-autocomplete-header .autocomplete-list a::before {
  content: "\e937";
}

header .headercontainer .mobileNav_container a[aria-expanded=true] .mobileNav {
  font-family: pf-icon !important;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: never;
  text-transform: none;
}

header .headercontainer .mobileNav_container a[aria-expanded=true] .mobileNav::before {
  content: "\e945";
}

header .headercontainer nav:not(.navmobile_container) ul .navtab .subnavigation .close-button {
  font-family: pf-icon !important;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: never;
  text-transform: none;
}

header .headercontainer nav:not(.navmobile_container) ul .navtab .subnavigation .close-button::before {
  content: "\e946";
}

header .headercontainer .mobileNav_container .mobileNav {
  font-family: pf-icon !important;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: never;
  text-transform: none;
}

header .headercontainer .mobileNav_container .mobileNav::before {
  content: "\e973";
}

header .headercontainer nav:not(.navmobile_container) ul .navtab .searchBtn {
  font-family: pf-icon !important;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: never;
  text-transform: none;
}

header .headercontainer nav:not(.navmobile_container) ul .navtab .searchBtn::before {
  content: "\e9a2";
}

header {
  background-color: #fff;
  height: 66px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}

@media print, screen and (max-width: 39.99875em) {
  header {
    height: 50px;
  }
}
header ul {
  list-style-type: none;
  margin: 0;
}

header ul ul {
  margin: 0;
}

header .headercontainer {
  font-size: 0.75rem;
  max-width: 70.3125rem;
  padding: 0;
  font-weight: bold;
  position: relative;
}

@media print, screen and (max-width: 39.99875em) {
  header .headercontainer > .grid-x {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: justify;
  }
}
header .headercontainer .mobileNav_container {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: calc(16.6666666667% - 1.25rem);
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  margin-left: 0;
  margin-right: 0;
}

@media print, screen and (min-width: 40em) {
  header .headercontainer .mobileNav_container {
    display: none;
  }
}
header .headercontainer .mobileNav_container .mobileNav,
header .headercontainer .mobileNav_container .mobileConnection {
  font-size: 2rem;
}

header .headercontainer .logocontainer {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: calc(15% - 1.25rem);
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  margin-left: 0;
  margin-right: auto;
  min-width: 15%;
  padding: 0 0.3rem;
}

@media print, screen and (max-width: 39.99875em) {
  header .headercontainer .logocontainer {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: calc(66.6666666667% - 1.875rem);
    margin-left: 0.9375rem;
    margin-right: 0.9375rem;
  }
  header .headercontainer .logocontainer img {
    max-height: 4.168em;
  }
}
header .headercontainer nav:not(.navmobile_container) {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: calc(57% - 1.25rem);
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  height: 66px;
  margin-right: 0;
  margin-left: 0;
}

@media print, screen and (max-width: 39.99875em) {
  header .headercontainer nav:not(.navmobile_container) {
    display: none;
  }
}
header .headercontainer nav:not(.navmobile_container) ul {
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}

header .headercontainer nav:not(.navmobile_container) ul .navtab {
  height: 100%;
}

header .headercontainer nav:not(.navmobile_container) ul .navtab .mainnav_item {
  color: #292c2e;
  display: block;
  height: 100%;
  width: 100%;
}

header .headercontainer nav:not(.navmobile_container) ul .navtab .mainnav_item .tabfiller {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}

@media print, screen and (max-width: 63.99875em) {
  header .headercontainer nav:not(.navmobile_container) ul .navtab .mainnav_item .tabfiller {
    font-size: 0.65rem;
  }
}
header .headercontainer nav:not(.navmobile_container) ul .navtab .mainnav_item .tabfiller::after {
  background-color: var(--primary-color);
  border-radius: 0.25rem 0.25rem 0 0;
  bottom: 0;
  content: "";
  display: none;
  height: 0.25rem;
  left: 12.5%;
  position: absolute;
  width: 75%;
}

header .headercontainer nav:not(.navmobile_container) ul .navtab .mainnav_item .tabfiller:hover::after {
  display: block;
}

header .headercontainer nav:not(.navmobile_container) ul .navtab .mainnav_item[aria-expanded=true] {
  color: var(--primary-color);
}

header .headercontainer nav:not(.navmobile_container) ul .navtab .mainnav_item[aria-expanded=true] .tabfiller::after {
  display: block;
}

header .headercontainer nav:not(.navmobile_container) ul .navtab:not(:last-child) .mainnav_item span {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-right: 1px solid #c3c3c3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 2.25rem;
}

header .headercontainer nav:not(.navmobile_container) ul .navtab .subnavigation {
  background-color: #f2f3f3;
  -webkit-box-shadow: inset 0 22px 16px -16px #e6e6e6;
          box-shadow: inset 0 22px 16px -16px #e6e6e6;
  font-weight: normal;
  left: 0;
  padding: 2.25rem 1rem 1.875rem;
  position: absolute;
  top: 100%;
  width: 100%;
}

header .headercontainer nav:not(.navmobile_container) ul .navtab .subnavigation .subnavigation_hero {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: calc(23% - 1.25rem);
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  position: relative;
}

@media print, screen and (max-width: 47.99875em) {
  header .headercontainer nav:not(.navmobile_container) ul .navtab .subnavigation .subnavigation_hero {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: calc(32.6% - 1.875rem);
    margin-left: 0.9375rem;
    margin-right: 0.9375rem;
  }
}
header .headercontainer nav:not(.navmobile_container) ul .navtab .subnavigation .subnavigation_hero .subnavigation_hero_title {
  color: var(--primary-color);
  font-size: 1.875rem;
  font-weight: bold;
  line-height: 2rem;
  margin: 0;
  padding-bottom: 1.25rem;
}

header .headercontainer nav:not(.navmobile_container) ul .navtab .subnavigation .subnavigation_itemsTable {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: calc(77% - 1.25rem);
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}

@media print, screen and (max-width: 47.99875em) {
  header .headercontainer nav:not(.navmobile_container) ul .navtab .subnavigation .subnavigation_itemsTable {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: calc(67.4% - 1.875rem);
    margin-left: 0.9375rem;
    margin-right: 0.9375rem;
  }
}
header .headercontainer nav:not(.navmobile_container) ul .navtab .subnavigation .subnavigation_itemsTable > ul {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

header .headercontainer nav:not(.navmobile_container) ul .navtab .subnavigation .subnavigation_itemsTable .subnavigation_itemTable_category {
  min-width: 200px;
}

header .headercontainer nav:not(.navmobile_container) ul .navtab .subnavigation .subnavigation_itemsTable .subnavigation_itemTable_category .title {
  border-bottom: 1px solid #d9d9d9;
  font-size: 0.75rem;
  font-weight: bold;
  min-height: 4.25rem;
  padding: 0.625rem 0;
  text-transform: uppercase;
}

header .headercontainer nav:not(.navmobile_container) ul .navtab .subnavigation .subnavigation_itemsTable .subnavigation_itemTable_category .title a {
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  padding-right: 0.5rem;
  position: relative;
}

header .headercontainer nav:not(.navmobile_container) ul .navtab .subnavigation .subnavigation_itemsTable .subnavigation_itemTable_category .title a::after {
  content: ">";
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

header .headercontainer nav:not(.navmobile_container) ul .navtab .subnavigation .subnavigation_itemsTable .subnavigation_itemTable_category .title a > div {
  font-size: 2.2rem;
  min-width: 2.5rem;
}

header .headercontainer nav:not(.navmobile_container) ul .navtab .subnavigation .subnavigation_itemsTable .subnavigation_itemTable_category .title a span {
  border-right-style: none;
}

header .headercontainer nav:not(.navmobile_container) ul .navtab .subnavigation .subnavigation_itemsTable .subnavigation_itemTable_category ul {
  margin: 1rem 0 0.5rem;
}

header .headercontainer nav:not(.navmobile_container) ul .navtab .subnavigation .subnavigation_itemsTable .subnavigation_itemTable_category ul li {
  margin-bottom: 0.375rem;
}

header .headercontainer nav:not(.navmobile_container) ul .navtab .subnavigation .subnavigation_itemsTable .subnavigation_itemTable_category ul li a {
  color: #292c2e;
  font-size: 0.75rem;
}

header .headercontainer nav:not(.navmobile_container) ul .navtab .subnavigation .subnavigation_itemsTable .subnavigation_itemTable_category ul li a:hover {
  text-decoration: underline;
}

header .headercontainer nav:not(.navmobile_container) ul .navtab .subnavigation .close-button {
  height: 20px;
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 20px;
  display: block;
  height: 1.5rem;
  width: 1.5rem;
}

header .headercontainer nav:not(.navmobile_container) ul .navtab .subnavigation .search-bar {
  padding: 0 20%;
}

header .headercontainer nav:not(.navmobile_container) ul .navtab .subnavigation .search-bar .h1 {
  margin-bottom: 1rem;
}

header .headercontainer nav:not(.navmobile_container) ul .navtab .subnavigation .search-bar__input {
  width: 100%;
  position: relative;
}

header .headercontainer nav:not(.navmobile_container) ul .navtab .subnavigation .search-bar__input .inbenta-autocomplete-header {
  background-color: #fff;
  border-radius: 8px;
  top: 2rem;
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  display: none;
  position: absolute;
  width: 100%;
  z-index: 1;
}

header .headercontainer nav:not(.navmobile_container) ul .navtab .subnavigation .search-bar__input .inbenta-autocomplete-header .autocomplete-list {
  margin: 0 1em;
  padding: 0;
}

header .headercontainer nav:not(.navmobile_container) ul .navtab .subnavigation .search-bar__input .inbenta-autocomplete-header .autocomplete-list .autocomplete-item-header {
  border-top: 1px solid #cacaca;
  position: relative;
}

header .headercontainer nav:not(.navmobile_container) ul .navtab .subnavigation .search-bar__input .inbenta-autocomplete-header .autocomplete-list .autocomplete-item-header::before {
  content: "";
  opacity: 0;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

header .headercontainer nav:not(.navmobile_container) ul .navtab .subnavigation .search-bar__input .inbenta-autocomplete-header .autocomplete-list .autocomplete-item-header:hover::before {
  background-color: var(--background-color);
  border-radius: 4px;
  content: "";
  display: block;
  height: 75%;
  opacity: 0.5;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 100%;
}

header .headercontainer nav:not(.navmobile_container) ul .navtab .subnavigation .search-bar__input .inbenta-autocomplete-header .autocomplete-list .autocomplete-item-header:first-child {
  border-top: 0;
}

header .headercontainer nav:not(.navmobile_container) ul .navtab .subnavigation .search-bar__input .inbenta-autocomplete-header .autocomplete-list a {
  color: #2d2d2d;
  display: block;
  padding: 0.85em 0.5em;
  position: relative;
  width: 100%;
}

header .headercontainer nav:not(.navmobile_container) ul .navtab .subnavigation .search-bar__input .inbenta-autocomplete-header .autocomplete-list a::before {
  position: absolute;
  right: 0.5em;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

header .headercontainer nav:not(.navmobile_container) ul .navtab .subnavigation .search-bar__input input {
  height: 2.5rem;
  border: 1px solid #c9c9c9;
  border-radius: 0.25rem;
  background: #fff;
  padding: 0.5rem 1rem;
  font-size: 1rem;
}

header .headercontainer nav:not(.navmobile_container) ul .navtab .subnavigation .search-bar__input input:focus-visible {
  outline: none;
}

header .headercontainer nav:not(.navmobile_container) ul .navtab .subnavigation .search-bar__input__button {
  width: 3rem;
  background-color: var(--primary-color);
  color: #fff;
  opacity: 0.5;
  pointer-events: none;
}

header .headercontainer nav:not(.navmobile_container) ul .navtab .subnavigation .search-bar__input__button:focus-visible {
  border: 3px dashed #292c2e;
}

header .headercontainer nav:not(.navmobile_container) ul .navtab .subnavigation .search-bar__input__button.active {
  opacity: 1;
  pointer-events: initial;
  cursor: pointer;
}

header .headercontainer nav:not(.navmobile_container) ul .navtab .subnavigation .search-bar__input__button i {
  font-size: 1.5rem;
}

header .headercontainer nav:not(.navmobile_container) ul .navtab span {
  padding: 0 0.75rem;
}

@media print, screen and (max-width: 63.99875em) {
  header .headercontainer nav:not(.navmobile_container) ul .navtab span {
    padding: 0 0.4rem;
  }
}
header .headercontainer nav:not(.navmobile_container) ul .navtab span a {
  color: #292c2e;
}

header .headercontainer nav:not(.navmobile_container) ul .navtab .searchBtn {
  color: var(--primary-color);
  font-size: 2rem;
  margin: 0 0.75rem;
  padding: 0;
}

@media print, screen and (max-width: 63.99875em) {
  header .headercontainer nav:not(.navmobile_container) ul .navtab .searchBtn {
    font-size: 1.5rem;
    margin: 0;
  }
}
header .headercontainer .logincontainer {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: calc(30% - 1.25rem);
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  margin-left: 0;
  margin-right: 0;
  text-transform: uppercase;
  -webkit-box-pack: right;
      -ms-flex-pack: right;
          justify-content: right;
}

@media print, screen and (max-width: 39.99875em) {
  header .headercontainer .logincontainer {
    display: none;
  }
}
header .headercontainer .logincontainer ul {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 0.25rem;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  overflow: hidden;
}

header .headercontainer .logincontainer ul li {
  max-width: 15em;
}

header .headercontainer .logincontainer ul li span {
  display: inline-block;
  line-height: 1.7;
  margin-right: 0.6em;
}

header .headercontainer .logincontainer ul li a {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  height: 100%;
  padding: 0.5rem 0.3rem;
}

@media print, screen and (max-width: 63.99875em) {
  header .headercontainer .logincontainer ul li a {
    font-size: 0.6875rem;
    padding: 0.5rem;
  }
}
header .headercontainer .logincontainer ul li a.virementExpress {
  background-color: #e6e6e6;
  color: #292c2e;
}

header .headercontainer .logincontainer ul li a.virementExpress i {
  font-size: 0.9rem;
}

header .headercontainer .logincontainer ul li a.accesClient {
  background-color: var(--primary-color);
  color: #fff;
}

header .headercontainer .logincontainer ul li a.accesClient:focus-visible {
  border: 3px dashed #292c2e;
}

header .headercontainer .logincontainer ul li a.accesClient i {
  font-size: 1.5rem;
}

header .headercontainer .logincontainer ul li a i {
  margin: 0 0.6rem;
}

header .navmobile_container {
  background-color: #fff;
  left: 0;
  position: fixed;
  text-align: left;
  top: 50px;
  width: 100%;
  overflow-x: scroll;
  height: calc(100% - 50px);
}

header .navmobile_container .navmobile_item:not(.subnav_item),
header .navmobile_container .navmobile_item:not(.search-mob) {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-top: 1px solid #d9d9d9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: relative;
  width: 100%;
}

header .navmobile_container .navmobile_item:not(.subnav_item) a,
header .navmobile_container .navmobile_item:not(.search-mob) a {
  color: #292c2e;
  font-size: 1rem;
  height: 100%;
  padding: 1rem 1.125rem;
  position: relative;
  width: 100%;
}

header .navmobile_container .navmobile_item:not(.subnav_item) a i,
header .navmobile_container .navmobile_item:not(.search-mob) a i {
  color: #cacaca;
  font-size: 1.5rem;
  position: absolute;
  right: 2rem;
}

header .navmobile_container .navmobile_item:not(.subnav_item) a:hover,
header .navmobile_container .navmobile_item:not(.search-mob) a:hover {
  background-color: var(--primary-color);
  color: #fff;
}

header .navmobile_container .navmobile_item:not(.subnav_item) a:hover i,
header .navmobile_container .navmobile_item:not(.search-mob) a:hover i {
  color: #fff;
}

header .navmobile_container .navmobile_item:not(.subnav_item) a.active,
header .navmobile_container .navmobile_item:not(.search-mob) a.active {
  background-color: var(--primary-color);
  color: #fff;
  text-align: center;
}

header .navmobile_container .navmobile_item:not(.subnav_item) a.active i,
header .navmobile_container .navmobile_item:not(.search-mob) a.active i {
  color: #fff;
  left: 1rem;
  right: auto;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

header .navmobile_container .navmobile_item:not(.subnav_item) a div[class^=i-],
header .navmobile_container .navmobile_item:not(.search-mob) a div[class^=i-] {
  color: var(--primary-color);
  font-size: 2rem;
  margin-right: 10px;
}

header .navmobile_container .navmobile_item:not(.subnav_item) .navmobile_item_pictoholder,
header .navmobile_container .navmobile_item:not(.search-mob) .navmobile_item_pictoholder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  padding: 1rem 1.125rem;
  width: 100%;
}

header .navmobile_container .navmobile_item:not(.subnav_item) .navmobile_item_pictoholder a,
header .navmobile_container .navmobile_item:not(.search-mob) .navmobile_item_pictoholder a {
  width: auto;
}

header .navmobile_container .navmobile_item:not(.subnav_item) .navmobile_item_pictoholder a::after,
header .navmobile_container .navmobile_item:not(.search-mob) .navmobile_item_pictoholder a::after {
  content: none;
}

header .navmobile_container .navmobile_item:not(.subnav_item) .navmobile_panel,
header .navmobile_container .navmobile_item:not(.search-mob) .navmobile_panel {
  width: 100%;
}

header .navmobile_container .navmobile_item:not(.subnav_item) .navmobile_panel a:hover,
header .navmobile_container .navmobile_item:not(.search-mob) .navmobile_panel a:hover {
  background-color: initial;
  color: #292c2e;
}

header .navmobile_container .navmobile_item:not(.subnav_item) .navmobile_panel a::after,
header .navmobile_container .navmobile_item:not(.search-mob) .navmobile_panel a::after {
  background-position: -197px 0;
}

header .navmobile_container .navmobile_item.search-mob {
  font-size: 1rem;
  height: 100%;
  padding: 1rem 1.125rem;
  width: 100%;
}

header .navmobile_container .navmobile_item.search-mob .search-bar {
  width: calc(100% - 1rem);
}

header .navmobile_container .navmobile_item.search-mob .search-bar .h1 {
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

header .navmobile_container .navmobile_item.search-mob .search-bar__input {
  width: 100%;
}

header .navmobile_container .navmobile_item.search-mob .search-bar__input .inbenta-autocomplete-header {
  background-color: #fff;
  border-radius: 8px;
  bottom: 2.5rem;
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  display: none;
  position: absolute;
  width: 100%;
  z-index: 1;
}

header .navmobile_container .navmobile_item.search-mob .search-bar__input .inbenta-autocomplete-header .autocomplete-list {
  margin: 0 1em;
  padding: 0;
}

header .navmobile_container .navmobile_item.search-mob .search-bar__input .inbenta-autocomplete-header .autocomplete-list .autocomplete-item-header {
  border-top: 1px solid #cacaca;
  position: relative;
}

header .navmobile_container .navmobile_item.search-mob .search-bar__input .inbenta-autocomplete-header .autocomplete-list .autocomplete-item-header::before {
  content: "";
  opacity: 0;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

header .navmobile_container .navmobile_item.search-mob .search-bar__input .inbenta-autocomplete-header .autocomplete-list .autocomplete-item-header:hover::before {
  background-color: var(--background-color);
  border-radius: 4px;
  content: "";
  display: block;
  height: 75%;
  opacity: 0.5;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 100%;
}

header .navmobile_container .navmobile_item.search-mob .search-bar__input .inbenta-autocomplete-header .autocomplete-list .autocomplete-item-header:first-child {
  border-top: 0;
}

header .navmobile_container .navmobile_item.search-mob .search-bar__input .inbenta-autocomplete-header .autocomplete-list a {
  color: #2d2d2d;
  display: block;
  padding: 0.85em 0.5em;
  position: relative;
  width: 100%;
}

header .navmobile_container .navmobile_item.search-mob .search-bar__input .inbenta-autocomplete-header .autocomplete-list a::before {
  position: absolute;
  right: 0.5em;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

header .navmobile_container .navmobile_item.search-mob .search-bar__input input {
  height: 1.875rem;
  border: 1px solid #c9c9c9;
  border-radius: 0.25rem;
  background: #fff;
  padding: 0.2rem 1rem;
  font-size: 1rem;
}

header .navmobile_container .navmobile_item.search-mob .search-bar__input__button {
  width: 2.4rem;
  background-color: var(--primary-color);
  color: #fff;
  opacity: 0.5;
  pointer-events: none;
}

header .navmobile_container .navmobile_item.search-mob .search-bar__input__button.active {
  opacity: 1;
  pointer-events: initial;
  cursor: pointer;
}

header .navmobile_container .navmobile_item.search-mob .search-bar__input__button i {
  font-size: 1.5rem;
}

header .navmobile_container .navmobile_item.subnav_item a {
  padding: 0.8rem 1.125rem;
}

header .navmobile_container .navmobile_item.subnav_item a i {
  color: var(--primary-color);
}

header .navmobile_container .navmobile_item.subnav_item a:hover i {
  color: var(--primary-color);
}

header .navmobile_container.navmobile_login_container .navmobile_item {
  background-color: var(--primary-color);
}

header .navmobile_container.navmobile_login_container .navmobile_item a {
  color: #fff;
}

header .navmobile_container.navmobile_login_container .navmobile_item a::after {
  content: none;
}

header .navmobile_container.navmobile_login_container .navmobile_item a.accesClient i, header .navmobile_container.navmobile_login_container .navmobile_item a.virementExpress i {
  color: #fff;
  margin-right: 1.5rem;
  position: relative;
  right: 0;
}

.navigation_overlay {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 50;
}