/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.legal-notices {
  font-size: 0.75rem;
  margin: 2rem 0;
}

.legal-notices__title {
  color: #8a8a8a;
  font-weight: bold;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}

.legal-notices__content__item-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.3rem;
}

.legal-notices__content__item-wrapper sup {
  text-align: right;
  top: 0.2rem;
  width: 1rem;
}

.legal-notices__content__item-wrapper p {
  margin-bottom: 0;
}

.legal-notices__content__item-wrapper .rte {
  width: calc(100% - 1rem);
}

.legal-notices__content__item-wrapper a:hover {
  text-decoration: underline;
}

.legal-notices__content__item-wrapper .grid-container {
  margin: 0;
  padding: 0 0.1rem;
}